import * as React from "react";
import type { HeadFC } from "gatsby";
import styled from "styled-components";

import { Container } from "../components/Grid";
import { Layout } from "../components/Layout";


const ScMain = styled.div`
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: #020203;

  .post-full-content {
    background-color: transparent;
  }
`;

const LegalPage = () => {
  return (
    <Layout>
      <ScMain>
        <Container>
          <article className="post-full post no-image page no-image">
            <h1>Security Policy</h1>
            <section className="post-full-content">
              <div className="post-content">
                <p>
                  PowerTrade values the work done by security researchers in
                  improving the security of our products and service offerings.
                  We are committed to working with this community to verify,
                  reproduce, and respond to legitimate reported vulnerabilities.
                </p>
                <h3 id="responsible-disclosure-guidelines">
                  Responsible Disclosure Guidelines
                </h3>
                <p>
                  We will investigate legitimate reports and make every effort
                  to quickly correct any vulnerability. To encourage responsible
                  reporting, we will not take legal action against you nor ask
                  law enforcement to investigate you provided you comply with
                  the following Responsible Disclosure Guidelines:
                </p>
                <ul>
                  <li>
                    Provide details of the vulnerability, including information
                    needed to reproduce and validate the vulnerability and a
                    Proof of Concept (POC).
                  </li>
                  <li>
                    Make a good faith effort to avoid privacy violations,
                    destruction of data, and interruption or degradation of our
                    services.
                  </li>
                  <li>
                    Do not modify or access data that does not belong to you.
                  </li>
                  <li>
                    Give PowerTrade a reasonable time to correct the issue
                    before making any information public.
                  </li>
                </ul>
                <h3 id="smart-contract-security-audits">
                  Smart Contract Security Audits
                </h3>
                <p>
                  Smart contract security audit reports can be accessed below.
                </p>
                <p>
                  <strong>Quantstamp</strong>
                  <br />
                  <a
                    href="https://certificate.quantstamp.com/full/power-trade"
                    target="_blank"
                  >
                    Full Report
                  </a>
                </p>
                <p>
                  <strong>Halborn Inc</strong>
                  <br />
                  <a
                    href="https://github.com/HalbornSecurity/PublicReports/blob/master/Solidity%20Smart%20Contract%20Audits/PowerTrade_Fuel_smartcontract_halborn_report_V1.pdf"
                    target="_blank"
                  >
                    Token Contract
                  </a>
                  <br />
                  <a
                    href="https://github.com/HalbornSecurity/PublicReports/blob/master/Solidity%20Smart%20Contract%20Audits/PowerTrade_vesting_smartcontract_halborn_report_V1.pdf"
                    target="_blank"
                  >
                    Vesting Contract
                  </a>
                </p>
              </div>
            </section>
          </article>
        </Container>
      </ScMain>
    </Layout>
  );
};

export default LegalPage;

export const Head: HeadFC = () => <title>Security Policy</title>;
